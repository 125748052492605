.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.avatar-render {
  margin-top: 40px;
  box-shadow: -1px 0px 10px black;
  border-radius: 10px;
}

.input-video{
  margin-top: 40px;
}

.camera-btn {
  margin-top: 10px ;
}

.btn-control {
  margin-left: 10px;
}

.start-stop-btn {
  margin-left: 10px;
}

video {
  height: 600px;
  width: 700px;
}

/* Avatar canvas width and height */
.render-canvas {
  height: 600px;
  width: 700px;
  border-radius: 10px;
}

.app-container{
  height: auto;
}

.app-container .overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8) url("assets/images/spinner.png") center
    no-repeat;
}

/* Turn off scrollbar when body element has the loading class */
.loading {
  overflow: hidden;
}

.loading .App {
  display: none;
}

/* Make spinner image visible when body element has the loading class */
.loading .overlay {
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width : 540px) {
  video{
    height: fit-content;
    width:100%;
  }

  canvas{
    width: 100%;
  }

  .avatar-render {
    margin-top: none;
    width: 100%;
  }
  
  .render-canvas{
    width: 100%;
  }
}
